import React from "react";
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useRouter } from "next/router";
import { translate } from "../../../helper/languageBasedData";
import HeaderMenuItemCard from "./HeaderMenuItemCard";

const HeaderMenu = ({ productList, serviceList, handleMenuClose }) => {
  const router = useRouter();
  // console.log("productList", productList);

  return (
    <ul className="d-flex align-items-center justify-content-center fw-semibold">
      <li className="mx-3">
        <Link
          href="/"
          className={`d-block py-4 px-0 text-green-hover fs-18 lh-24 text-white position-relative text-nowrap ${router.route == "/" ? "active" : ""}`}
          onClick={() => handleMenuClose()}>

          <span className="text">Home</span>

        </Link>
      </li>

      <li className="position-relative mx-3">
        <Link
          href="/"
          className={`d-block py-4 px-0 text-green-hover fs-18 lh-27 text-white position-relative text-nowrap ${router.route.includes("/service") ? "active" : ""}`}
          onClick={() => handleMenuClose()}>

          <span className="text">Services</span>
          <span className="icon ms-2">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>

        </Link>
        {serviceList && serviceList.length > 0 &&
          <ul className="position-absolute text-start bg-white transition-all-3 py-2 px-2 radius-16">
            <div className="row">
              {serviceList.map((serviceGroup, indexGroup) => {
                if (serviceGroup && serviceGroup.length > 0) {
                  return (
                    <div className="col-lg-6" key={indexGroup}>
                      {serviceGroup.map((service, index) => {
                        if (service) {
                          // console.log("service", service);
                          return (
                            <li key={index}>
                              <Link
                                href="/service/[slug]"
                                as={`/service/${service.slug}`}
                                className={`d-flex px-2 py-2  ${router.asPath == ("/service/" + service.slug) ? "active" : ""}`}
                                onClick={() => handleMenuClose()}>

                                <HeaderMenuItemCard
                                  icon={service.image}
                                  title={translate(service.title)}
                                  description={service.sub_title}
                                />

                              </Link>
                            </li>
                          );
                        }
                      })}
                    </div>
                  );
                }
              })}
            </div>
          </ul>
        }
      </li>
      <li className="position-relative mx-3">
        <Link
          href="/"
          className={`d-block py-4 px-0 text-green-hover fs-18 lh-27 text-white position-relative text-nowrap ${router.route.includes("/product") ? "active" : ""}`}>

          <span className="text">Our Products</span>
          <span className="icon ms-2">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>

        </Link>
        {productList && productList.length > 0 &&
          <ul className="position-absolute text-start bg-white transition-all-3 py-2 px-2 radius-16">
            <div className="row">
              {productList.map((productGroup, indexGroup) => {
                if (productGroup && productGroup.length > 0) {
                  return (
                    <div className="col-lg-6" key={indexGroup}>
                      {productGroup.map((product, index) => {
                        if (product) {
                          return (
                            <li key={index}>
                              <Link
                                href="/product/[slug]"
                                as={`/product/${product.slug}`}
                                className={`d-flex px-2 py-2  ${router.asPath == ("/product/" + product.slug) ? "active" : ""}`}
                                onClick={() => handleMenuClose()}>

                                <HeaderMenuItemCard
                                  icon={product.image}
                                  title={product.product_name}
                                  description={product.sub_title}
                                />

                              </Link>
                            </li>
                          );
                        }
                      })}
                    </div>
                  );
                }
              })}
            </div>
          </ul>
        }
      </li>
      <li className="mx-3">
        <Link
          href="/case-studies"
          className={`d-block py-4 px-0 text-green-hover fs-18 lh-24 text-white position-relative text-nowrap ${router.route.includes("/case-studies") ? "active" : ""}`}
          onClick={() => handleMenuClose()}>

          <span className="text">Case Studies</span>

        </Link>
      </li>
      <li className="mx-3">
        <Link
          href="/about"
          className={`d-block py-4 px-0 text-green-hover fs-18 lh-24 text-white position-relative text-nowrap ${(router.route.includes("/about") || router.route.includes("/blogs") || router.route.includes("/careers") || router.route.includes("/contact")) ? "active" : ""}`}
          onClick={() => handleMenuClose()}>

          <span className="text">About Us</span>
          <span className="icon ms-2">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>

        </Link>
        <ul className="position-absolute text-start bg-white transition-all-3 py-2 px-2 radius-16">
          <li>
            <Link
              href="/about"
              className={`d-flex px-3 py-1 text-nowrap ${router.route.includes("/about") ? "active" : ""}`}
              onClick={() => handleMenuClose()}>

              <span className="text">Our Story</span>

            </Link>
          </li>
          <li>
            <Link
              href="/blogs"
              className={`d-flex px-3 py-1 text-nowrap ${router.route.includes("/blogs") ? "active" : ""}`}
              onClick={() => handleMenuClose()}>

              <span className="text">Latest Articles</span>

            </Link>
          </li>
          <li>
            <Link
              href="/careers"
              className={`d-flex px-3 py-1 text-nowrap ${router.route.includes("/careers") ? "active" : ""}`}
              onClick={() => handleMenuClose()}>

              <span className="text">Careers</span>

            </Link>
          </li>
          <li>
            <Link
              href="/contact"
              className={`d-flex px-3 py-1 text-nowrap ${router.route.includes("/contact") ? "active" : ""}`}
              onClick={() => handleMenuClose()}>

              <span className="text">Contact Us</span>

            </Link>
          </li>
        </ul>
      </li>
    </ul>
  );
};


export default HeaderMenu;