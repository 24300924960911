import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import '../public/css/bundle.css';
import '../styles/style.scss';
import '../styles/responsive.scss';

import dynamic from 'next/dynamic';
import React from 'react';
import App from 'next/app';
import Router from 'next/router';
import NProgress from 'nprogress';
import { Provider as ReduxProvider } from 'react-redux';
import { createWrapper } from 'next-redux-wrapper';
import { connect } from 'react-redux';
import AlertTemplate from '../components/common/alert/AlertTemplate';
import store from '../redux/store';
import { ToastContainer } from 'react-toastify';

Router.events.on('routeChangeStart', (url) => {
	NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const MainLayout = dynamic(() => import('../components/common/layouts/MainLayout'), { loading: () => '' });
const DefaultLayout = dynamic(() => import('../components/common/layouts/DefaultLayout'), { loading: () => '' });

class MyApp extends App {
	constructor(props) {
		super(props);
		this.state = {
			domLoading: true,
		};
	}

	static async getInitialProps({ Component, ctx }) {
		const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
		return { pageProps: pageProps };
	}

	componentDidMount() {
		// if(typeof window !== 'undefined') {
		//     //console.log('componentDidMount');
		//     window.addEventListener("load", this.handleDomLoaded);
		// } else {
		//     this.handleDomLoaded();
		// }
	}

	componentWillUnmount() {
		// if(typeof window !== 'undefined') {
		//     //console.log('componentWillUnmount');
		//     window.removeEventListener("load", this.handleDomLoaded);
		// } else {
		//     this.handleDomLoaded();
		// }
	}
	handleDomLoaded = (e) => {
		this.setState({
			domLoading: false,
		});
	};

	getDeafultLayout = (page) => {
		return <DefaultLayout>{page}</DefaultLayout>;
	};

	render() {
		const { Component, pageProps } = this.props;
		const Layout =
			Component.Layout ||
			function (page) {
				return <DefaultLayout>{page}</DefaultLayout>;
			};

		return (
			<>
				<ReduxProvider store={store}>
					<MainLayout>
						<ToastContainer />
						{/* <Layout>
              <Component {...pageProps} />
            </Layout> */}
						{Layout(<Component {...pageProps} />)}
					</MainLayout>
				</ReduxProvider>
			</>
		);
	}
}

const makeStore = () => store;
const wrapper = createWrapper(makeStore);

export default wrapper.withRedux(connect(null, null)(MyApp));
